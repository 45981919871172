<template>

    <b-container>
        
        <!-- <showAt breakpoint="large">
            <div class="d-flex" style="position: relative; background-color: #262642; height: 226px; width: 100%; border-radius: 16px; padding: 32px; color: #FFF;">
                <div style="width: 65%;">
                    <h2 class="text-white" style="font-size: 2rem;">
                        Создаем майнинг-бизнес <br>  «под ключ» и управляем им за Вас
                    </h2>
                    <ul style="font-size: 15px; line-height: 22px; padding: 16px ;">
                        <li>365 дней в году гарантируем бесперебойный хэшрейт;</li>
                        <li>100% компенсируем недополученной прибыли во время какого-либо простоя.</li>
                    </ul>
                </div>
                <div class="video-card-image" style="position: absolute; right: 0; top: 0; bottom: 0; width: 50%;"></div>
            </div>
        </showAt>
        
         <showAt breakpoint="medium">
            <div class="d-flex" style="position: relative; background-color: #262642; overflow: hidden; height: 186px; width: 100%; border-radius: 16px; padding: 32px; color: #FFF;">
                <div style="width: 80%; z-index: 2;">
                    <h2 class="text-white" style='font-size: 20px !important;'>
                        Создаем майнинг-бизнес <br>  «под ключ» и управляем им за Вас
                    </h2>
                    <ul style="font-size: 15px; line-height: 22px; padding: 16px ;">
                        <li>365 дней в году гарантируем бесперебойный хэшрейт;</li>
                        <li>100% компенсируем недополученной прибыли во время какого-либо простоя.</li>
                    </ul>
                </div>
                <div class="video-card-image" style="position: absolute; right: -20%; top: 0; bottom: 0; width: 50%; z-index: 1;"></div>
            </div>
        </showAt>
        
        <showAt breakpoint="small">
            <div style="background-color: #262642; width: 100%; border-radius: 16px; padding: 16px; color: #FFF; position: relative; padding-bottom: 175px; overflow: hidden;">
                <h2 class="text-white" style="font-size: 2rem;">
                    Создаем майнинг-бизнес «под ключ» и управляем им за Вас
                </h2>
                   
               <div class="video-card-image" style="position: absolute; left: 0; right: 0; height: 225px;  bottom: -55px; z-index: 0; background-position: center;"></div>
                <ul style="font-size: 15px; line-height: 22px; padding: 16px ; z-index: 99;">
                    <li style="margin-bottom: 8px;">365 дней в году гарантируем бесперебойный хэшрейт;</li>
                    <li>100% компенсируем недополученной прибыли во время какого-либо простоя.</li>
                </ul>  
            </div>
        </showAt>
        
        <showAt breakpoint='large'>
            <b-row style="margin: 28px 0 52px 0;">
                <b-col style="margin-bottom: 24px;">
                    <div class="d-flex">
                        <div class="form-group-with-label" style="min-width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                            <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                        </div>
                        <div>
                            Ежедневно начисляем прибыль  и списываем плату за обслуживание
                        </div>
                    </div>
                </b-col>
                <b-col style="margin-bottom: 24px;">
                    <div class="d-flex">
                        <div class="form-group-with-label" style="min-width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                            <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                        </div>
                        <div>
                            Поломки, ремонт неисправностей  и обслуживание за наш счет
                        </div>
                    </div>
                </b-col>
                <b-col style="margin-bottom: 24px;">
                    <div class="d-flex">
                        <div class="form-group-with-label" style="min-width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                            <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                        </div>
                        <div>
                            Возможность сдать в trade-in  и сделать upgrade в любой момент
                        </div>
                    </div>
                </b-col>
            </b-row>
        </showAt>
        
        <showAt breakpoint='medium'>
            <b-row style="margin: 28px 0 52px 0;">
                <b-col style="margin-bottom: 24px;">
                    <div class="d-flex flex-column justify-content-start">
                        <div class="form-group-with-label" style="min-width: 40px; width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                            <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                        </div>
                        <div style='margin-top: 20px;'>
                            Ежедневно начисляем прибыль  и списываем плату за обслуживание
                        </div>
                    </div>
                </b-col>
                <b-col style="margin-bottom: 24px;">
                    <div class="d-flex flex-column justify-content-start">
                        <div class="form-group-with-label" style="min-width: 40px; width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                            <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                        </div>
                        <div style='margin-top: 20px;'>
                            Поломки, ремонт неисправностей  и обслуживание за наш счет
                        </div>
                    </div>
                </b-col>
                <b-col style="margin-bottom: 24px;">
                   <div class="d-flex flex-column justify-content-start">
                        <div class="form-group-with-label" style="min-width: 40px; width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                            <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                        </div>
                        <div style='margin-top: 20px;'>
                            Возможность сдать в trade-in  и сделать upgrade в любой момент
                        </div>
                    </div>
                </b-col>
            </b-row>
        </showAt>
        
        <showAt breakpoint='small'>
            <b-row style="margin: 28px 0 52px 0;">
                <b-col style="margin-bottom: 24px;">
                    <div class="d-flex">
                        <div class="form-group-with-label" style="min-width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                            <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                        </div>
                        <div>
                            Ежедневно начисляем прибыль  и списываем плату за обслуживание
                        </div>
                    </div>
                </b-col>
                <b-col style="margin-bottom: 24px;">
                    <div class="d-flex">
                        <div class="form-group-with-label" style="min-width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                            <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                        </div>
                        <div>
                            Поломки, ремонт неисправностей  и обслуживание за наш счет
                        </div>
                    </div>
                </b-col>
                <b-col style="margin-bottom: 24px;">
                    <div class="d-flex">
                        <div class="form-group-with-label" style="min-width: 40px; height: 40px; margin: 0; padding: 0; text-align: center; line-height: 34px; margin-right: 20px;">
                            <feather-icon icon="BarChartIcon"  size="24" style="color: var(--theme-color)" />
                        </div>
                        <div>
                            Возможность сдать в trade-in  и сделать upgrade в любой момент
                        </div>
                    </div>
                </b-col>
            </b-row>
        </showAt>
         -->
        
        <b-card-title>
            {{$t('catalog.select-appropriate-miner')}}
        </b-card-title>

        <b-card-title>

            <validation-observer ref="filter" class="slider-hover-prevent miners-filter">
                <b-row>
                    <b-col md="3" lg="3">
                    
                        <b-form-group class="form-group-with-label">
                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                <div style="flex-grow: 1;">

                                    <validation-provider
                                        #default="{ errors }"
                                        name="Prices"
                                        :rules="rules"
                                    >
                                        <div v-if='errors[0]' class='text-danger'>
                                            <span  style="font-size: 12px; font-weight: 500;">
                                                {{$t("catalog.price")}}
                                            </span>
                                            <feather-icon icon='AlertCircleIcon' />
                                        </div>
                                        <span v-else class='text-muted' style="font-size: 12px;">{{$t("catalog.price")}} </span>

                                        <div class="d-flex" style="font-size: 14px; padding: 4px 4px 4px 0px;">
                                            <span> {{filter.price | range}} </span>
                                        </div>

                                    </validation-provider>

                                </div>
                                <div class="mt-auto mb-auto">
                                    <span class="text-muted" style="font-size: 14px;">$</span>
                                </div>
                            </div>

                            <vue-slider
                                v-model="filter.price"
                                :min="0"
                                :max="20000"
                                :dotSize="16"
                                :height="2"
                                :contained="false"
                                :interval="50"
                                :duration="0.25"
                                :enable-cross="false"
                                tooltip="none"
                            />

                        </b-form-group>

                    </b-col>

                    <b-col md="3" lg="3">

                        <b-form-group class="form-group-with-label mult-select">
                            <div class="d-flex justify-content-between">
                                <div style="flex-grow: 1;">
                                    <validation-provider #default="{ errors }" rules="">
                                        <span
                                        :class="{
                                            'text-muted': errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger': errors.length > 0 ? true : false,
                                        }"
                                        style="font-size: 12px;"
                                        >{{$t("catalog.characteristics.producer")}}</span
                                        >
                                        <v-select
                                        :options="miners.manufacturers"
                                        v-model="filter.manufacturers"
                                        label="title"
                                        :clearable="false"
                                        :placeholder="$t('catalog.placeholders.select-brand')"
                                        multiple
                                        >
                                        <template slot="no-options">
                                            {{$t("catalog.placeholders.no-search-results")}}
                                        </template>
                                        </v-select>
                                    </validation-provider>
                                </div>
                            </div>
                        </b-form-group>

                    </b-col>

                        <b-col md="3" lg="3">

                        <b-form-group class="form-group-with-label mult-select">
                            <div class="d-flex justify-content-between">
                                <div style="flex-grow: 1;">
                                    <validation-provider #default="{ errors }" rules="">
                                        <span
                                        :class="{
                                            'text-muted': errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger': errors.length > 0 ? true : false,
                                        }"
                                        style="font-size: 12px;"
                                        >{{$t("catalog.cryptocurrency")}}</span
                                        >
                                        <v-select
                                            :options="miners.coins"
                                            v-model="filter.coins"
                                            label="name"
                                            :clearable="false"
                                            :placeholder="$t('catalog.placeholders.select-currency')"
                                            multiple
                                        >
                                        <template slot="no-options">
                                            {{$t("catalog.placeholders.no-search-results")}}
                                        </template>
                                        </v-select>
                                    </validation-provider>
                                </div>
                            </div>
                        </b-form-group>

                    </b-col>

                    <b-col md="3" lg="3">
                    
                        <b-form-group class="form-group-with-label">
                            <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                                <div style="flex-grow: 1;">

                                    <validation-provider
                                        #default="{ errors }"
                                        name="Prices"
                                        :rules="rules"
                                    >
                                        <div v-if='errors[0]' class='text-danger'>
                                            <span  style="font-size: 12px; font-weight: 500;">
                                                {{$t("catalog.hashrate")}}
                                            </span>
                                            <feather-icon icon='AlertCircleIcon' />
                                        </div>
                                        <span v-else class='text-muted' style="font-size: 12px;">{{$t("catalog.hashrate")}}</span>

                                        <div class="d-flex" style="font-size: 14px; padding: 4px 4px 4px 0px;">
                                            <span> {{filter.ths | range}} </span>
                                        </div>

                                    </validation-provider>

                                </div>
                                <div class="mt-auto mb-auto">
                                    <span class="text-muted" style="font-size: 14px;">Th/s</span>
                                </div>
                            </div>

                            <vue-slider
                                v-model="filter.ths"
                                :min="0"
                                :max="300"
                                :dotSize="16"
                                :height="2"
                                :contained="false"
                                :interval="1"
                                :duration="0.25"
                                :enable-cross="false"
                                tooltip="none"
                            />

                        </b-form-group>

                    </b-col>
                    
                    <b-col md="3" lg="3" >
                        <b-row class="pt-1">
                            <b-col lg="12" >
                                <div class="d-flex">
                                    <b-form-checkbox
                                        id="f-new"
                                        class="border-radius mr-2"
                                        v-model="filter.new"
                                        name="checkbox-1"
                                    >
                                        {{$t("catalog.btns.only-new")}}
                                    </b-form-checkbox>

                                    <b-form-checkbox
                                        id="f-used"
                                        class="border-radius"
                                        v-model="filter.used"
                                        name="checkbox-2"
                                    >
                                        {{$t("catalog.btns.only-used")}}
                                    </b-form-checkbox>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col md="4" lg="6">
                    </b-col>

                    <b-col md="5" lg="3" class="pt-1">
                        <div class="d-flex">
                        <b-button @click="get" size="sm" block variant="primary">
                            {{$t('catalog.btns.submit')}}
                        </b-button>

                        <div class="d-flex pl-1" style="text-align: end; font-size: 14px; cursor: pointer; align-items: center;" @click="clear">
                            <feather-icon
                                icon="XIcon"
                                size="14"
                                style="color: #426FF6; padding: 1px"
                            />
                            <span style="color: #426FF6; white-space: nowrap;">{{$t('catalog.btns.reset-filter')}}</span>
                        </div>
                        </div>
                    </b-col>

                </b-row>
            </validation-observer>

        </b-card-title>

        <b-overlay
            :show="is_loading"
            spinner-variant="primary"
            variant="transparent"
            style="min-height: 50vh;"
        >
            <b-card-body v-if='miners.items.length === 0 && !is_loading' class='d-flex flex-column p-4'>
                <div class="d-flex flex-column justify-content-center">
                    <div class="nodata-icon ml-auto mr-auto"></div>
                    <p class="text-muted mt-1 ml-auto mr-auto text-center">
                        {{$t('contracts.no-data')}}
                    </p>
                </div>
            </b-card-body>

            <miner-card-view v-else-if="!is_loading" v-for="(miner, index) in miners.items" :key="index" :item="miner" :diff_percent="diff_percent">
                <div slot="action">
                    <template v-if="ifIsInCart(miner)" >
                        <b-form-spinbutton
                            size="md"
                            inline
                            v-model="miners.cart[miner.id].count"
                            :min="0"
                            :max="500"
                            @change="preferCountValueChanged( miner, $event )"
                        />
                    </template>
                    <template v-else >
                        <b-button @click="addToCart( $event, miner )" variant="outline-primary">{{$t('catalog.btns.add-to-cart')}}</b-button>
                    </template>
                </div>
            </miner-card-view>

        </b-overlay>

    <h1 class="visually-hidden">Купить оборудование для майнинга</h1>
	<p class="visually-hidden">Уже не первый год многие уверенно говорят о том, что будущее финансовых рынков за криптовалютами. К майнингу, то есть процессу выработки альтернативных цифровых денег, приобщается всё больше интернет-пользователей. Вместе с ростом популярности добычи цифровых монет, усложняется сам процесс их создания. Рассмотрим подробнее, какое оборудование выгоднее купить майнерам для добычи биткоинов и альткоинов, а также какова сумма первоначальных инвестиций криптовалютчиков.</p>
    <h2 class="visually-hidden">Типология оборудования для добычи криптовалют</h2>
	<p class="visually-hidden">Прежде чем купить оборудование для майнинга, важно тщательно разобраться, какое «железо» обладает наибольшей эффективностью, в чём преимущества и недостатки различных моделей работы.</p>
	<p class="visually-hidden">В зависимости от устройств, которые используются для майнинга, можно выделить три основных способа добычи цифровых монет:</p>
		<ul class="visually-hidden">
			<li class="visually-hidden">Майнинг криптовалют на процессоре. Центральный процессор (CPU) – это первое устройство, которое стало использоваться для поиска новых блоков в блокчейне. С повышением сложности сетей наиболее популярных криптовалют, прежде всего, биткоина, проведение вычислительных операций с помощью процессоров утратило свою рентабельность. Сейчас купить оборудование для майнинга в виде специализированного процессора экономически целесообразно лишь в том случае, когда планируется генерация монет относительно новых криптовалют.</li>
			<li class="visually-hidden">Майнинг с использованием видеокарт. GPU можно рассматривать как устройства, способные производить огромное количество однотипных операций. Это свойство превращает их в тот инструмент, в котором заинтересованы добытчики криптовалют. Особенно востребованы сейчас так называемые фермы, то есть системы, содержащие несколько мощных графических ускорителей. Купить оборудование для майнинга в виде видеокарт – достаточно простое решение, ведь преобразующие графические образы устройства можно найти практически в любом магазине техники, причём не только в Москве, но и по всей России.</li>
			<li class="visually-hidden">Добыча цифровых активов с помощью асиков, то есть устройств, специфика которых состоит в настройке на работу с одним алгоритмом хеширования. Купить майнер в виде асика – значит получить возможность добывать все монеты, построенные на основе конкретного алгоритма, при этом возможность перехода на другой алгоритм будет исключена.</li>
		</ul>
	<p class="visually-hidden">Итак, сегодня «шахтёры» криптовалютного рынка чаще используют для работы ASIC и видеокарты. Проанализируем более детально их преимущества и недостатки с точки зрения времени окупаемости и доступности на рынке.</p>
    <h3 class="visually-hidden">ASIC & видеокарты</h3>  
	<p class="visually-hidden">С понятием видеокарты – специального адаптера, который служит для преобразования графических образов – так или иначе знакомы практически все пользователи персональных компьютеров. Слово «асик» тоже в последнее время на слуху, однако далеко не каждый до конца представляет себе механизмы его работы.</p>
	<p class="visually-hidden">Слово асик – ASIC – происходит от английского Application Specific Integrated Circui, что переводится на русский язык как интегральная схема специального назначения. Её можно сравнить с чипом, объединяющим в себе блок питания, небольшую плату и необходимые для обеспечения работоспособности инструмента охлаждающие устройства. ASIC подходит лишь для тех, кто решился выйти на рынок цифровых валют и купить оборудование для майнинга, – для выполнения других задач он непригоден.</p>
	<p class="visually-hidden">Видеокарты, которые используются майнерами, выпускают компании: Nvidia, наибольшим спросом пользуется её серия GeForce, и AMD, известная моделями Radeon. Мировым лидером в области производства ASIC является корпорация Bitmain Technologies со штаб-квартирой Пекине.</p>
	<p class="visually-hidden">Сейчас считается, что вложения в асик окупаются быстрее, нежели инвестиции в ферму, сформированную из нескольких видеокарт. Ещё одно достоинство специализированного устройства для криптовалютчиков заключается в том, что ASIC-майнер легче обслуживать.</p>
	<p class="visually-hidden">При этом графические процессы значительно выигрывают у асиков по уровню доступности. Сегодня они в наличии во многих магазинах, в том числе у онлайн-ритейлеров, предлагающих бесплатную доставку. Купить асик-майнер несколько сложнее: желаемое оборудование порой приходится заказывать в Китае или США. Время доставки ASIC в таких случаях может растянуться на два-три месяца. К тому же видеокарты обладают бόльшей рентабельностью на вторичном рынке, ведь, помимо майнеров, их активно используют геймеры.</p>
	<p class="visually-hidden">Тем не менее, для добычи криптовалюты в промышленных масштабах сейчас гораздо больше подходят как раз ASIC-майнеры. Цена оборудования в среднем составляет 10 тысяч долларов. Она колеблется в зависимости от хешрейта, энергопотребления, валюты, которую можно генерировать, и других факторов. В специализированных интернет-магазинах готовы сообщить примерную доходность майнера и срок его окупаемости.</p>
	<p class="visually-hidden">Итак, добыча криптовалют – достаточно новый, но уже имеющий высокую потенциальную доходность вид деятельности. Главное – выбрать для него отвечающий вызовам времени майнер, цена которого будет оптимально соотноситься с мощностью и уровнем энергопотребления.</p>
        
    </b-container>
    
</template>

<script>
    
    import miners from "@/modules/contents/miners"
    import MinerCardView from "@/components/MinerCard"

    import VueSlider from 'vue-slider-component'
    import vSelect from "vue-select";

    import { ValidationProvider, ValidationObserver } from 'vee-validate'

    export default {
        
        metaInfo() {
            const title = this.$t('title-pages.catalog');
            return {
                title: title,
                titleTemplate: null,
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: 'На сайте представлен широкий выбор оборудования для майнинга криптовалюты, покупка майнера на Qubity имеет ряд преимуществ: ежедневное начисление прибыли, гарантия бесперебойного хешрейта 365 дней в году, поломки и ремонт неисправностей за наш счет, а также возможность вернуть майнер, сдать в Trade in'
                    }
                ]
            }
        },
        data() {
            return {
                miners,

                is_loading: false,

                diff_percent: 1.64,
                months_count: 12,
                rules: "minint:100|maxint:10000",
                sliderOptions: {
                    step: 100,
                    max: 10000,
                    min: 100
                },
                filter: {
                    price: [0, 20000],
                    ths: [0, 300],
                    manufacturers: [],
                    coins: [{id:1, name:'Bitcoin', short: 'BTC'}],
                    new: true,
                    used: true,
                }

            }
        },
        methods: {
            
            preferCountValueChanged( miner, count ) {
                if( count === 0 ) {
                    this.$delete(this.miners.cart, miner.id);
                }
            },
            
            ifIsInCart( miner ) {
                return this.miners.cart.hasOwnProperty( miner.id );
            },
            
            addToCart( args, miner ) {
                if( this.miners.cart.hasOwnProperty( miner.id ) ) {
                    return;                    
                } else {
                    this.$set( this.miners.cart, miner.id, {
                        miner: miner,
                        count: 1,
                        guarantee: {
                            period: 'none',
                            ths: false,
                            amount: 0
                        }
                    });                                   
                }
                
                args.stopPropagation();
                
            },

            getPercentForPeriod() {
                
                if( this.months_count < 1 ) {
                    this.months_count = 1;
                } else if( this.months_count > 24 ) {
                    this.months_count = 24;
                }

                this.months_count = this.months_count.toFixed(0)
                
                this.$request.get("utils.getDiffHistory", {
                    period: `${this.months_count}months`
                }).then(result => {
                    this.diff_percent = parseFloat((result / this.months_count).toFixed(2));
                });
            },

            clear() {
                this.filter =  {
                    price: [0, 20000],
                    ths: [0, 300],
                    manufacturers: [],
                    coins: [],
                    new: true,
                    used: true,
                }

                this.get()

            },

            get() {
                this.is_loading = true;
                this.miners.get(true, this.filter, () => {this.is_loading = false});
            }
            
        },
        filters: {
            range(val) {
                return val[0].formatMoney(0, '', ' ') + ' — ' + val[1].formatMoney(0, '', ' ');
            }
        },
        components: {
            MinerCardView,
            VueSlider,
            ValidationProvider,
            ValidationObserver,
            vSelect
        },
        watch: {

        },
        mounted() {
            this.get()
            this.getPercentForPeriod()
        }

    }

</script>

<style lang="scss">
    .miners-filter {
        .vs__selected {
            background-color: transparent;

            .vs__deselect {
                margin-left: 2px;
                margin-right: 8px;
            }
        }
        .form-group-with-label {
            background-color: #FFF;
            border: 1px solid #FFF;
        }
        .vs__dropdown-menu {
            font-size: 14px;
        }
        .vue-slider-process {
            background-color: #426FF6;
        }
        .vue-slider-dot-handle {
            border: 6px solid #426FF6;
            outline: 2px solid #FFF;
        }
        .vs__open-indicator {
            margin-top: -18px;
            fill: rgba(60, 60, 60, 0.4);
        }
        .vs__dropdown-toggle {
            box-shadow: none !important;
        }
        .vs__actions {
            padding: 0px;
        }
        .vs__selected-options {
            margin-bottom: 4px;
        }
        .vs__search, .vs__search:focus {
            font-size: 14px;
        }
    }
    .mult-select {
        padding-bottom: 4px;
    }

</style>
